export const platos = {
  left : [
    {
      id: 1,
      name: 'Burga Champiñon',
      description: 'Nuestra famosisima burguer de champiñones rellenos de queso y magia',
      price: '$5.000'
    },
    {
      id: 2,
      name: 'Gado Gado',
      description: 'Nuestra famosisima burguer de champiñones rellenos de queso y magia',
      price: '$5.000'
    },
    {
      id: 3,
      name: 'Clementine Bauch',
      description: 'Nuestra famosisima burguer de champiñones rellenos de queso y magia',
      price: '$5.000'
    },
    {
      id: 4,
      name: 'Patricia Lebsack',
      description: 'Nuestra famosisima burguer de champiñones rellenos de queso y magia',
      price: '$5.000'
    },
    {
      id: 5,
      name: 'Chelsey Dietrich',
      description: 'Nuestra famosisima burguer de champiñones rellenos de queso y magia',
      price: '$5.000'
    },
    {
      id: 6,
      name: 'Mrs. Dennis Schulist',
      description: 'Nuestra famosisima burguer de champiñones rellenos de queso y magia',
      price: '$5.000'
    },
    {
      id: 7,
      name: 'Kurtis Weissnat',
      description: 'Nuestra famosisima burguer de champiñones rellenos de queso y magia',
      price: '$5.000'
    },
    {
      id: 8,
      name: 'Nicholas Runolfsdottir V',
      description: 'Nuestra famosisima burguer de champiñones rellenos de queso y magia',
      price: '$5.000'
    },
    {
      id: 9,
      name: 'Glenna Reichert',
      description: 'Nuestra famosisima burguer de champiñones rellenos de queso y magia',
      price: '$5.000'
    },
    {
      id: 10,
      name: 'Clementina DuBuque',
      description: 'Nuestra famosisima burguer de champiñones rellenos de queso y magia',
      price: '$5.000'
    }
  ],
  right:[
    {
      id: 1,
      name: 'Burga Champiñon',
      description: 'Nuestra famosisima burguer de champiñones rellenos de queso y magia',
      price: '$5.000'
    },
    {
      id: 2,
      name: 'Gado Gado',
      description: 'Nuestra famosisima burguer de champiñones rellenos de queso y magia',
      price: '$5.000'
    },
    {
      id: 3,
      name: 'Clementine Bauch',
      description: 'Nuestra famosisima burguer de champiñones rellenos de queso y magia',
      price: '$5.000'
    },
    {
      id: 4,
      name: 'Patricia Lebsack',
      description: 'Nuestra famosisima burguer de champiñones rellenos de queso y magia',
      price: '$5.000'
    },
    {
      id: 5,
      name: 'Chelsey Dietrich',
      description: 'Nuestra famosisima burguer de champiñones rellenos de queso y magia',
      price: '$5.000'
    },
    {
      id: 6,
      name: 'Mrs. Dennis Schulist',
      description: 'Nuestra famosisima burguer de champiñones rellenos de queso y magia',
      price: '$5.000'
    },
    {
      id: 7,
      name: 'Kurtis Weissnat',
      description: 'Nuestra famosisima burguer de champiñones rellenos de queso y magia',
      price: '$5.000'
    },
    {
      id: 8,
      name: 'Nicholas Runolfsdottir V',
      description: 'Nuestra famosisima burguer de champiñones rellenos de queso y magia',
      price: '$5.000'
    },
    {
      id: 9,
      name: 'Glenna Reichert',
      description: 'Nuestra famosisima burguer de champiñones rellenos de queso y magia',
      price: '$5.000'
    },
    {
      id: 10,
      name: 'Clementina DuBuque',
      description: 'Nuestra famosisima burguer de champiñones rellenos de queso y magia',
      price: '$5.000'
    }
  ]
};
