import React, { Component } from 'react'; 
import Logo from './LogoVS.png';
import './CarouselCard.css';
import Fondo from './6.1.jpeg'



class CarouselCard extends Component {
    render() {
      return (
       
       <div className="tc" style={{postion: "relative"}}>
          
            <img classname="imgFondo" src={Fondo} alt="fondo"/>
            
       
            <div className="titleContainer tc  cover " >
                        <div className="LogoImg">
                            <img src={Logo} alt='shit'></img>
                        </div>
                    <div className="textTitle">
                        <p style={{ backgroundColor: "rgb(0,0,0,0.8)"}}> 
                            COMIDA 
                            <p className="tituloVeg"> VEGETARIANA </p> 
                            PARA PALADAR 
                            <p className="textCarnivoro">Carnivoro</p>   
                        </p>
                    </div>
            </div>
        </div>
      );
    }
  }
  
  export default CarouselCard;