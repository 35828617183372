import React, { Component } from 'react'; 
import CarouselCard from './CarouselCard.js';

class MainCarousel extends Component {
    render() {
      return (
        <div >
         <CarouselCard/>
        </div>
      );
    }
  }
  
  export default MainCarousel;