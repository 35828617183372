import React, { Component } from 'react';
import MainCarousel from './MainCarousel.js';
import Mapa from './Components/Mapa/GMaps.js';
import Menu from './Components/Menu/Menu.js'

class App extends Component {
  render() {
    return (
      <div >
        {/* <Navbar/>  */}
        <MainCarousel/>
        <Menu/>
        <div 
          style={{
            position : "relative",
            height: "50vh",
          }}>
          <Mapa/> 
        </div>
      </div>
    );
  }
}

export default App;
