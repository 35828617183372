import React, { Component } from 'react';
import { Grid, Divider, Container } from 'semantic-ui-react'
import './Menu.css'; 
import MenuCard from './MenuCard/MenuCard.js';
import { platos } from './Platos/platos';
import map from "lodash/map";

class Menu extends Component {
  render() {
    return (
        <div className="bgMenu">
            <div className="tituloMenu">Conoce nuestro Menú </div>
            <div className="subtituloMenu">Los menjores platos de nuestra cocina, pero recuerda que siempre tenemos sorpresitas de más.</div>
            <div >
              <Grid stackable columns={2}>
                <Grid.Column className="columna">
                <Divider />
                  <Container padded>
                    {
                      map(platos.left, (plato) => {
                        return(
                          <MenuCard plato={plato}/>
                        )
                      })
                    }
                  </Container>
                </Grid.Column>
                <Grid.Column>
                <Divider />
                  <Container padded>
                    {
                        map(platos.right, (plato) => {
                          return(
                            <MenuCard plato={plato}/>
                          )
                        })
                      }
                  </Container>
                </Grid.Column>
              </Grid>
            </div>
        </div>
    );
  }
}

export default Menu;