import React, { Component } from 'react';
import './MenuCard.css';
import map from "lodash/map"

class MenuCard extends Component {
    constructor(props){
        super(props)
    }
    render() {
        let { plato } = this.props;
        let { id, name, description, price } = plato;
        return (
            <div className="bgCardMenu" key={id}>
                <div className="tituloCardMenu"> {name} </div>
                <div className="card2line">
                    <div className="subtituloCardMenu">{description}</div>
                    <div className="lineaPuntos">.................................................................</div>
                    <div className="precio">{price}</div>
                </div>
            </div> 
        );
    }
}

export default MenuCard;