import React, { Component } from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';


class Mapa extends Component {
  render() {
    return (
      <Map 
          initialCenter={{
            lat: -33.442634,
            lng: -70.626295
          }}
          google={this.props.google} 
          zoom={17}
      >
 
      <Marker onClick={this.onMarkerClick}
              name={'Current location'} />

      <InfoWindow onClose={this.onInfoWindowClose}>
          <div>
            {/* <h1>{this.state.selectedPlace.name}</h1> */}
          </div>
      </InfoWindow>
    </Map>
    );
  }
}

// export default Mapa;
export default GoogleApiWrapper({
  apiKey: ("AIzaSyCacM_bNjSS-P-UoHKIzLWCOjHsL-MpWbg")
})(Mapa)